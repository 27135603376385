import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['container', 'content']

  initialize() {
    super.initialize()

    this._clickHandler = this._clickHandler.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.openModal = this.openModal.bind(this)

    // Goal: Trap click events, look for any on modal links, and ensure we open the modal.
    // If the element that was clicked has a data-reflex attribute, its click event will be trapped by StimulusReflex,
    // so we have to get creative, so we also check the stimulus-reflex:before. The _clickHandler is idempotent.
    document.addEventListener('click', this._clickHandler)
    document.addEventListener('stimulus-reflex:before', this._clickHandler)
    document.addEventListener('modal:open', this.openModal)
    document.addEventListener('modal:close', this.closeModal)
  }

  connect() {
    super.connect()

    // Trap click events, so we can close the modal when the user clicks outside the content region. Behind the content
    // region is the container, which goes edge-to-edge. So a click outside the content must be on the container.
    this.containerTarget.addEventListener('click', event => {
      if(event.target == this.containerTarget) {
        this.closeModal()
      }
    })
  }

  closeModal() {
    this.containerTarget.classList.add('hidden')
    this.contentTarget.innerHTML = 'Loading&hellip; ' + window.spinnerTemplate
    this.containerTarget.dispatchEvent(new Event('modal:closed'))
  }

  openModal() {
    this.containerTarget.classList.remove('hidden')
    this.containerTarget.dispatchEvent(new Event('modal:opened'))
  }

  _clickHandler(event) {
    let element = event.target.closest('[data-turbo-frame="modal"]')
    if(element?.dataset?.turboFrame == 'modal' || event.detail?.element?.dataset?.turboFrame == 'modal') {
      this.openModal()
    }
  }

  handleSubmit(event) {
    if (event.detail.success) {
      this.closeModal()
    }
  }
}
