import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    disableWith: String,
    disabledClass: String,
    spinnerClass: String,
  }

  connect() {
    super.connect()
    this._instrumentEvents()
  }

  disconnect() {
    this._uninstrumentEvents()
    super.disconnect()
  }

  replaceWithSpinner() {
    this.originalHTML = this.element.innerHTML
    this.element.innerHTML = this.disableWith
    if(this.hasDisabledClassValue) {
      this.element.classList.add(this.disabledClassValue)
    }
  }

  removeSpinner() {
    if(this.hasDisabledClassValue) {
      this.element.classList.remove(this.disabledClassValue)
    }
    this.element.innerHTML = this.originalHTML
  }

  get disableWith() {
    return ((this.hasDisableWithValue ? this.disableWithValue : "Sending...") + window.spinnerTemplate)
  }

  _instrumentEvents() {
    this._afterReflexCallback = this._afterReflexCallback.bind(this)
    this.element.addEventListener('stimulus-reflex:after', this._afterReflexCallback)
  }

  _uninstrumentEvents() {
    this.element.removeEventListener('stimulus-reflex:after', this._afterReflexCallback)
  }

  _afterReflexCallback() {
    this.removeSpinner()
  }
}
