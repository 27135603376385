import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    disableWith: String,
    onChange: String
  }
  static targets = [
    'button'
  ]

  connect() {
    super.connect()
    this._instrumentButtons()
    this._instrumentChanges()
  }

  disableSubmitButton(event) {
    this.buttons.forEach((button) => {
      button.innerHTML = this.disableWith
      button.disabled = true
      button.classList.add('disabled')
    })
  }

  enableSubmitButton(event) {
    this.buttons.forEach((button) => {
      button.innerHTML = this.originalText[button]
      button.disabled = false
      button.classList.remove('disabled')
    })
  }

  processChange(event) {
    this.stimulate(this.onChangeValue)
  }

  reset() {
    // Reset the form to default values (and cause multiselect to empty out)
    this.element.reset()

    // Now, empty out all of the remaining fields
    Array.from(this.element.elements).forEach((formElement)=>{
      switch (formElement.type.toLowerCase()) {
        case "text":
        case "password":
        case "textarea":
        case "hidden":
            formElement.value = ""
            break
        case "radio":
        case "checkbox":
          if (formElement.checked) {
            formElement.checked = false
          }
          break
        case "select-one":
        case "select-multi":
          formElement.selectedIndex = -1
          break
        default:
          break
      }
    })
  }

  _instrumentButtons() {
    this.disableWith = this.disableWithValue || "Sending..." + window.spinnerTemplate
    if(this.hasButtonTarget) {
      this.buttons = [this.buttonTarget]
    } else {
      this.buttons = this.element.querySelectorAll('button[type=submit], a.button')
    }

    this.originalText = {}
    this.buttons.forEach((button) => {
      this.originalText[button] = button.innerHTML
    })
    this.element.addEventListener('enableButtons', this.enableSubmitButton.bind(this))
  }

  _instrumentChanges() {
    if (this.hasOnChangeValue) {
      this.processChangeHandler = this.processChange.bind(this)
      this.element.querySelectorAll('input, textarea, trix-editor').forEach((elem)=>{
        elem.addEventListener('blur', this.processChangeHandler)
      })
      this.element.querySelectorAll('select').forEach((elem)=>{
        elem.addEventListener('change', this.processChangeHandler)
      })
      this.element.querySelectorAll('input[type=checkbox]').forEach((elem)=>{
        elem.addEventListener('change', this.processChangeHandler)
      })
    }
  }
}
