import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    ping: String
  }

  connect() {
    super.connect()

    if(!this.element.dataset.href) {
      return;
    }

    const magicLink = this.element.querySelector('.magic_link')
    if(magicLink) {
      this.magicLink = magicLink
    } else {
      this.magicLink = document.createElement('a')
      this.magicLink.href = this.element.dataset.href
      if(this.element.attributes.target) {
        this.magicLink.dataset.turboFrame = this.element.attributes.target.nodeValue
      } else {
        if(this.element.dataset.turboFrame) {
          this.magicLink.dataset.turboFrame = this.element.dataset.turboFrame
        }
        if(this.element.dataset.turboAction) {
          this.magicLink.dataset.turboAction = this.element.dataset.turboAction
        }
        if(this.element.dataset.method) {
          this.magicLink.setAttribute('method', this.element.dataset.method)
        }
      }
      this.magicLink.classList = 'magic_link hidden'
      this.element.prepend(this.magicLink)
    }

    this.click = this.click.bind(this)
    this.element.addEventListener('click', this.click)
  }

  click(event) {
    const clickedElement = event.target
    const interactiveElements = ['a', 'button', 'input', 'textarea', 'select', 'dropdown']

    // Filter out clicks on active links and form elements; we only want clicks that don't have a handler
    if(clickedElement.disabled || !interactiveElements.includes(clickedElement.localName)) {
      event.stopPropagation()
      event.preventDefault()

      if(this.hasPingValue) {
        fetch(this.pingValue, {method: 'POST'}) // Telemetry collection: fire and forget
      }

      // If the link opens a modal, make sure we know when the modal closes so we can
      // handle history correctly
      if(this.magicLink.dataset.turboFrame == 'modal') {
        document.querySelector('#modal')
          .addEventListener('modal:closed', this.modalCloseHandler.bind(this), {once: true})
      }

      const urlForHistory = this.element.dataset.historyUrl ? this.element.dataset.historyUrl : this.element.dataset.href

      // Non-same-origin links can't be pushed onto the state, and should be opened in a new window anyway
      // So rather than parsing links, if the pushState() call throws an error, we catch it go to new window
      try {
        history.pushState({}, '', urlForHistory)
      }
      catch(err) {
        this.magicLink.target = '_blank'
        this.magicLink.setAttribute('aria-label', 'opens in a new window')
      }
      this.magicLink.click()
    }
  }

  modalCloseHandler() {
    history.back()
  }
}

