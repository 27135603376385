import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    trackQuizId: Number,
    userId: Number
  }

  connect () {
    super.connect()

    this.quizCompleted = this.quizCompleted.bind(this)
    document.addEventListener('quiz.completed', this.quizCompleted)
  }

  disconnect () {
    super.connect()
    
    document.removeEventListener('quiz.completed', this.quizCompleted)
  }

  quizCompleted = event => {
    const { quizContext, quizId } = event.detail
    if (this.trackQuizIdValue == quizId && quizContext == "Pre-Assessment") {
      [...this.element.querySelectorAll('.disabled')].forEach((elem) => elem.classList.remove('disabled'))
    }
  }
}
