// Based on https://github.com/afcapel/stimulus-autocomplete

import ApplicationController from './application_controller'
import debounce from "lodash.debounce"

export default class extends ApplicationController {
  // the "pillbox" shows selected items, the "input" is displayed for text entry, the "hidden" is submitted,
  // and "results" is where the search results go
  static targets = ["pillbox", "input", "hidden", "results", "closeButtonTemplate"]
  static values = {
    minLength: Number,
    inputName: String,
    submitOnEnter: Boolean,
    url: String
  }

  connect() {
    this.resultsTarget.hidden = true

    this.inputTarget.setAttribute("autocomplete", "off")
    this.inputTarget.setAttribute("spellcheck", "false")

    this.mouseDown = false

    this.onInputChange = debounce(this.onInputChange.bind(this), 300)
    this.onResultsClick = this.onResultsClick.bind(this)
    this.onResultsMouseDown = this.onResultsMouseDown.bind(this)
    this.onInputBlur = this.onInputBlur.bind(this)
    this.onKeydown = this.onKeydown.bind(this)

    this.inputTarget.addEventListener("keydown", this.onKeydown)
    this.inputTarget.addEventListener("blur", this.onInputBlur)
    this.inputTarget.addEventListener("input", this.onInputChange)
    this.resultsTarget.addEventListener("mousedown", this.onResultsMouseDown)
    this.resultsTarget.addEventListener("click", this.onResultsClick)

    // Set up a blank so that, in case all the pills are removed, the field is still submitted
    // Without that, Rails won't update the field/association
    let input = document.createElement("input")
    input.name = this.inputNameValue
    this.hiddenTarget.append(input)

    if (typeof this.inputTarget.getAttribute("autofocus") === "string") {
      this.inputTarget.focus()
    }

    // Instrument the starting pills' close boxes
    this.pillboxTarget.querySelectorAll('.pill').forEach(
      (pill)=>{
        const removeButton = pill.querySelector('.remove_button')
        const id = pill.getAttribute('data-select-id')
        removeButton.addEventListener('click', ()=>{ this.removeValue(id) })
      }
    )

    // Listen for form reset events
    this.inputTarget.closest('form').addEventListener('reset', ()=>{
      this.hiddenTarget.querySelectorAll('input').forEach((el)=>{
        this.removeValue(el.getAttribute('value'))
      })
    })

  }

  disconnect() {
    if (this.hasInputTarget) {
      this.inputTarget.removeEventListener("keydown", this.onKeydown)
      this.inputTarget.removeEventListener("focus", this.onInputFocus)
      this.inputTarget.removeEventListener("blur", this.onInputBlur)
      this.inputTarget.removeEventListener("input", this.onInputChange)
    }
    if (this.hasResultsTarget) {
      this.resultsTarget.removeEventListener(
        "mousedown",
        this.onResultsMouseDown
      )
      this.resultsTarget.removeEventListener("click", this.onResultsClick)
    }
  }

  sibling(next) {
    const options = Array.from(
      this.resultsTarget.querySelectorAll(
        '[role="option"]:not([aria-disabled])'
      )
    )
    const selected = this.resultsTarget.querySelector('[aria-selected="true"]')
    const index = options.indexOf(selected)
    const sibling = next ? options[index + 1] : options[index - 1]
    const def = next ? options[0] : options[options.length - 1]
    return sibling || def
  }

  // Select the correct option from the dropdown, triggered by keyboard input like up/down arrows
  select(target) {
    for (const el of this.resultsTarget.querySelectorAll(
      '[aria-selected="true"]'
    )) {
      el.removeAttribute("aria-selected")
      el.classList.remove("active")
    }
    target.setAttribute("aria-selected", "true")
    target.classList.add("active")
    this.inputTarget.setAttribute("aria-activedescendant", target.id)
    this.inputTarget.scrollIntoView({block: 'center'})
  }

  onKeydown(event) {
    switch (event.key) {
      case "Escape":
        if (!this.resultsTarget.hidden) {
          this.hideAndRemoveOptions()
          event.stopPropagation()
          event.preventDefault()
        }
        break
      case "ArrowDown":
        {
          const item = this.sibling(true)
          if (item) this.select(item)
          event.preventDefault()
        }
        break
      case "ArrowUp":
        {
          const item = this.sibling(false)
          if (item) this.select(item)
          event.preventDefault()
        }
        break
      case "Tab":
        {
          const selected = this.resultsTarget.querySelector(
            '[aria-selected="true"]'
          )
          if (selected) {
            this.commit(selected)
          }
        }
        break
      case "Enter":
        {
          if(!this.resultsTarget.hidden) {
            // If the user has selected an option, use it
            let selected = this.resultsTarget.querySelector(
              '[aria-selected="true"]'
            )
            if(!selected) {
              // If there is only one option, use it
              const results = this.resultsTarget.querySelectorAll('li')
              if(results.length == 1) {
                selected = results[0]
              }
            }
            if (selected) {
              this.commit(selected)
            }
            if (!this.hasSubmitOnEnterValue) {
              event.preventDefault()
            }
          }
        }
        break
    }
  }

  onInputBlur() {
    if (this.mouseDown) return
    this.resultsTarget.hidden = true
  }

  commit(selected) {
    if (selected.hasAttribute("aria-disabled")) return

    if (selected instanceof HTMLAnchorElement) {
      selected.click()
      this.resultsTarget.hidden = true
      return
    }

    const textValue = this.extractTextValue(selected)
    const value = selected.getAttribute("data-autocomplete-multiselect-value") || selected.getAttribute("data-autocomplete-value") || textValue

    if (this.hasHiddenTarget) {
      // Clear the input text
      this.inputTarget.value = ''

      if (this.getPill(value)) return

      this.addValue(textValue, value)

      this.hiddenTarget.dispatchEvent(new Event("input"))
      this.hiddenTarget.dispatchEvent(new Event("change"))
    } else {
      this.inputTarget.value = value
    }

    this.inputTarget.focus()
    this.hideAndRemoveOptions()
  }

  getPill(value) {
    let pill = this.pillboxTarget.querySelector(".pill[data-select-id='" + value + "']")
    return pill
  }

  addPill(text, value) {
    let pill = document.createElement("div")
    pill.setAttribute('class', 'pill')
    pill.setAttribute('data-select-id', value)
    pill.appendChild(document.createTextNode(text))
    pill.appendChild(this.closeButtonTemplateTarget.content.cloneNode(true))
    pill.firstElementChild.addEventListener('click', ()=>{ this.removeValue(value) })
    this.pillboxTarget.appendChild(pill)
  }

  removePill(value) {
    let pill = this.getPill(value)
    if (pill) {
      pill.remove()
    }
  }

  addValue(text, value) {
    let input = document.createElement("input")
    input.name = this.inputNameValue
    input.setAttribute('value', value)
    this.hiddenTarget.append(input)
    this.addPill(text, value)
    this.inputTarget.innerHTML = ''
    this.broadcastChange(value, text, 'addition')
  }

  removeValue(value) {
    this.hiddenTarget.querySelectorAll("input[value='" + value + "']").forEach((el) => { el.remove() })
    this.removePill(value)

    this.hiddenTarget.dispatchEvent(new Event("input"))
    this.hiddenTarget.dispatchEvent(new Event("change"))
    this.broadcastChange(value, '', 'deletion')
  }

  broadcastChange(value, textValue, type) {
    this.element.dispatchEvent(
      new CustomEvent("autocompleteMultiselect.change", {
        bubbles: true,
        detail: { value: value, textValue: textValue, type: type }
      })
    )
  }

  onResultsClick(event) {
    if (!(event.target instanceof Element)) return
    const selected = event.target.closest('[role="option"]')
    if (selected) this.commit(selected)
  }

  onResultsMouseDown() {
    this.mouseDown = true
    this.resultsTarget.addEventListener(
      "mouseup",
      () => (this.mouseDown = false),
      { once: true }
    )
  }

  onInputChange() {
    this.element.removeAttribute("value")
    this.fetchResults()
  }

  identifyOptions() {
    let id = 0
    for (const el of this.resultsTarget.querySelectorAll(
      '[role="option"]:not([id])'
    )) {
      el.id = `${this.resultsTarget.id}-option-${id++}`
    }
  }

  hideAndRemoveOptions() {
    this.resultsTarget.hidden = true
    this.resultsTarget.innerHTML = null
  }

  fetchResults(ids = []) {
    const query = this.inputTarget.value.trim()
    if (!query || query.length < this.minLengthValue) {
      this.hideAndRemoveOptions()
      return
    }

    if (!this.hasUrlValue) return

    const headers = { "X-Requested-With": "XMLHttpRequest" }
    const url = new URL(this.urlValue, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    if (ids.length > 0) {
      params.append("ids", ids)
    } else {
      params.append("q", query)
    }
    url.search = params.toString()

    this.element.dispatchEvent(new CustomEvent("loadstart"))

    fetch(url.toString(), { headers })
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
        this.identifyOptions()
        const hasResults = !!this.resultsTarget.querySelector('[role="option"]')
        this.resultsTarget.hidden = !hasResults
        this.element.dispatchEvent(new CustomEvent("load"))
        this.element.dispatchEvent(new CustomEvent("loadend"))
      })
      .catch(() => {
        this.element.dispatchEvent(new CustomEvent("error"))
        this.element.dispatchEvent(new CustomEvent("loadend"))
      })
  }

  open() {
    if (!this.resultsTarget.hidden) return
    this.resultsTarget.hidden = false
    this.element.setAttribute("aria-expanded", "true")
    this.element.dispatchEvent(
      new CustomEvent("toggle", {
        detail: { input: this.input, results: this.results }
      })
    )
  }

  close() {
    if (this.resultsTarget.hidden) return
    this.resultsTarget.hidden = true
    this.inputTarget.removeAttribute("aria-activedescendant")
    this.element.setAttribute("aria-expanded", "false")
    this.element.dispatchEvent(
      new CustomEvent("toggle", {
        detail: { input: this.input, results: this.results }
      })
    )
  }

  extractTextValue = el =>
    el.hasAttribute("data-autocomplete-multiselect-label")
      ? el.getAttribute("data-autocomplete-multiselect-label")
      : el.textContent.trim()
}
