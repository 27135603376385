import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    publishableKey: String
  }

  async connect() {
    super.connect()
    this.stripe = Stripe(this.publishableKeyValue)

    this.checkoutButtonHandler = this.getSessionAndRedirectToStripe.bind(this)
    this.element.addEventListener('click', this.checkoutButtonHandler)
  }

  getSessionAndRedirectToStripe(event) {
    event.preventDefault();
    event.stopPropagation();

    // Create a new Checkout Session server-side (from create_checkout_path)
    fetch(this.element.href, {
      method: 'POST',
      headers: {
        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
      }
    })
    .then((response) => {
      return response.json();
    })
    .then((session) => {
      return this.stripe.redirectToCheckout({ sessionId: session.id });
    })
    .then((result) => {
      if (result.error) {
        alert(result.error.message);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }
}
