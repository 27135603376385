import ApplicationController from './application_controller'
// import debounce from "lodash.debounce"

export default class extends ApplicationController {
  connect() {
    super.connect()
    this._instrumentEvents()
  }

  disconnect() {
    this._uninstrumentEvents()
    super.disconnect()
  }

  dragStart(event) {
    event.dataTransfer.effectAllowed = "move"
    event.target.classList.add("dragging")
    this.draggedElement = event.target
  }

  dragOver(event) {
    if(this._figureOutDropTarget(event)) {
      event.preventDefault() // Allows drop event to fire
    }
    return true
  }

  dragEnter(event) {
    let dropTarget = this._figureOutDropTarget(event)
    if(dropTarget) {
      if(this.currentDragHover != dropTarget) {
        this.currentDragHover = dropTarget
        dropTarget.classList.add('drag_hover')
        this.element.querySelectorAll(`[draggable=true].drag_hover:not(.dragging):not([data-list-ordering-id-number-value='${dropTarget.getAttribute('data-list-ordering-id-number-value')}'])`).forEach((elem) => {
          elem.classList.remove('drag_hover')
        })
      }
      event.preventDefault()
    }
  }

  dragLeave(event) {
    let dropTarget = this._figureOutDropTarget(event)
    if(dropTarget) {
      event.preventDefault()
    }
  }

  dragEnd(event) {
    event.target.classList.remove('dragging')
    this.draggedElement = null

    this.element.querySelectorAll('[draggable=true].drag_hover').forEach((elem) => {
      elem.classList.remove('drag_hover')
    })
    this.currentDragHover = null
  }

  drop(event) {
    const dropTarget = this._figureOutDropTarget(event) || this.currentDragHover

    const positionComparison = dropTarget.compareDocumentPosition(this.draggedElement)
    if(positionComparison & Node.DOCUMENT_POSITION_FOLLOWING) {
      dropTarget.insertAdjacentElement('beforebegin', this.draggedElement)
    } else if (positionComparison & Node.DOCUMENT_POSITION_PRECEDING) {
      dropTarget.insertAdjacentElement('afterend', this.draggedElement)
    } else {
      this.log("couldn't drop", positionComparison)
    }

    event.preventDefault()
  }

  _figureOutDropTarget(event) {
    const dropTarget = event.target
    if(!dropTarget) {
      return null
    }

    return dropTarget.closest('[draggable=true]:not(.dragging)')
  }

  _instrumentEvents() {
    this.dragStart = this.dragStart.bind(this)
    this.dragOver = this.dragOver.bind(this)
    this.dragEnter = this.dragEnter.bind(this)
    this.dragLeave = this.dragLeave.bind(this)
    this.dragEnd = this.dragEnd.bind(this)
    this.drop = this.drop.bind(this)
    this.element.addEventListener('dragstart', this.dragStart)
    this.element.addEventListener('dragover', this.dragOver)
    this.element.addEventListener('dragenter', this.dragEnter)
    this.element.addEventListener('dragleave', this.dragLeave)
    this.element.addEventListener('dragend', this.dragEnd)
    this.element.addEventListener('drop', this.drop)
  }

  _uninstrumentEvents() {
    this.element.removeEventListener('dragstart', this.dragStart)
    this.element.removeEventListener('dragover', this.dragOver)
    this.element.removeEventListener('dragenter', this.dragEnter)
    this.element.removeEventListener('dragleave', this.dragLeave)
    this.element.removeEventListener('dragend', this.dragEnd)
    this.element.removeEventListener('drop', this.drop)
  }
}
