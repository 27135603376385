import ApplicationController from './application_controller'

// Simple controller for toggling a CSS class based on clicks. Makes no assumptions about starting state,
// so whatever the markup has will be toggled on click.
export default class extends ApplicationController {
  static values = {
    cssClass: String
  }

  connect() {
    super.connect()
    this._documentClickHandler = this._documentClickHandler.bind(this)
    this.status = 'closed'
  }

  _bindDocumentClicks() {
    document.addEventListener('click', this._documentClickHandler)
  }

  _unbindDocumentClicks() {
    document.removeEventListener('click', this._documentClickHandler)
  }

  _documentClickHandler(event) {
    const clickedElement = event.target
    const closestToggle = clickedElement.closest(`[data-controller="class-toggle"]`)

    if (closestToggle === this.element) {
      // No-op; let handling proceed as normal
    } else {
      // The click was outside this.element, so close it if open
      if (this.status === 'open') {
        this.toggle()
      }
    }
  }

  toggle() {
    if (this.status === 'closed') {
      this._bindDocumentClicks()
      this.status = 'open'
    } else {
      this._unbindDocumentClicks()
      this.status = 'closed'
    }
    this.element.classList.toggle(this.cssClassValue)
  }
}
