import ApplicationController from './application_controller'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Controller to wrap up Chart.js. The controller is really tightly coupled to the ChartComponent, takes the component's
// output, and wraps it all up into a Chart.js call. The only spicy thing in here is the definition of callback
// functions to format numbers with the appropriate prefix & suffix; otherwise, it's a pass-through between the
// component and the charting library.
export default class extends ApplicationController {
  static values = {
    type: String,
    chartData: String,
    labels: String,
    numberFormattingOptions: String,
    options: String,
    tooltips: String,
  }

  connect() {
    super.connect()

    this._initChart = this._initChart.bind(this)
    if (typeof Chart !== "undefined" && Chart !== null) {
      this._initChart()
    } else {
      document.addEventListener("turbo:load", this._initChart, false)
    }
  }

  disconnect() {
    document.removeEventListener("turbo:load", this._initChart)
    this._chart.destroy()
    super.disconnect()
  }

  // Initialize the Chart.js Chart object
  _initChart() {
    let opts = this.options
    opts.onResize = (chart) => { chart.resize() }

    let chartConfig = {
      data: {
        labels: this.labels,
        datasets: this.chartData.map((dataset) => ({
          ...dataset,
          yAxisID: dataset.yAxisID // Use the yAxisID from the dataset
        }))
      },
      options: opts
    }
    if(this.chartType) {
      chartConfig.type = this.chartType
      if(this.chartType === "tree") {
        chartConfig.plugins = [ChartDataLabels]
        chartConfig.options.plugins["datalabels"] = {
          align: 'right',
          formatter: (value, context) => {
            return value.name
          }
        }
        chartConfig.options.plugins.tooltip.enabled = false
        chartConfig.data.datasets[0].edgeLineBorderDash = (ctx) => {
          if(chartConfig.data.datasets[0].data[ctx.dataIndex + 1].line_type == "dotted_line") {
            return [6, 4]
          } else {
            return [] // Solid line per https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash
          }

        }
      }
    }

    this._chart = new Chart(this.element.querySelector("canvas").getContext("2d"), chartConfig)
    const loadingDiv = this.element.querySelector(".loading")
    if(loadingDiv) {
      loadingDiv.classList.remove('loading')
    }
  }

  get chart() {
    return this._chart
  }

  get chartType() {
    return this.typeValue
  }

  get chartData() {
    return this._chartData = this._chartData || JSON.parse(this.chartDataValue)
  }

  get labels() {
    return this._labels = this._labels || JSON.parse(this.labelsValue)
  }

  get tooltips() {
    return this._tooltips = this._tooltips || JSON.parse(this.tooltipsValue)
  }

  get numberFormattingOptions() {
    return this._numberFormattingOptions = this._numberFormattingOptions || JSON.parse(this.numberFormattingOptionsValue)
  }

  get options() {
    if(this._options) {
      return this._options
    }
    this._options = JSON.parse(this.optionsValue)
    this._options.scales.y.ticks.callback = (value, index, ticks) => this.numberFormattingOptions.prefix + value + this.numberFormattingOptions.suffix
    this._options.plugins.tooltip.callbacks = {
      label: (context) => {
        if(this.tooltips[context.dataset.label]?.[context.dataIndex]) {
          return this.tooltips[context.dataset.label][context.dataIndex]
        } else if(this.tooltips[context.dataset.label]?.[context.label]) {
          return this.tooltips[context.dataset.label][context.label]
        } else {
          return this.numberFormattingOptions.prefix + context.parsed.y + this.numberFormattingOptions.suffix
        }
      }
    }
    // For now, the only annotations we have are Chart::HorizontalLine objects. If that changes, this code will need adjusting.
    if(this._options?.plugins?.annotation?.annotations) {
      const hovers = {
        enter({element}, event) {
          element.label.options.display = true
          return true;
        },
        leave({element}, event) {
          element.label.options.display = false
          return true
        }
      }
      Object.entries(this._options.plugins.annotation.annotations).forEach(([key, value]) => {
        this._options.plugins.annotation.annotations[key] = {...value, ...hovers}
      })
    }
    return this._options
  }
}
