import ApplicationController from './application_controller'

//                        _  _
//                      _/0\/ \_
//             .-.   .-` \_/\0/ '-.
//            /:::\ / ,_________,  \
//           /\:::/ \  '. (:::/  `'-;
//           \ `-'`\ '._ `"'"'\__    \
//            `'-.  \   `)-=-=(  `,   |
//                \  `-"`      `"-`   /

export default class extends ApplicationController {
  static targets = ["acceptButton", "rejectButton", "closeButton"]

  connect() {
    super.connect()

    this.cookieName = 'opted_out_cookie_categories'

    this._setOptOutCookie = this._setOptOutCookie.bind(this)
    this.acceptAll = this.acceptAll.bind(this)
    this.rejectAll = this.rejectAll.bind(this)
    this.closeCookieManagerPane = this.closeCookieManagerPane.bind(this)

    this.acceptButtonTargets.forEach((elem) => { elem.addEventListener('click', this.acceptAll) })
    this.rejectButtonTargets.forEach((elem) => { elem.addEventListener('click', this.rejectAll) })
    this.closeButtonTargets.forEach((elem) => { elem.addEventListener('click', this.closeCookieManagerPane) })

    this.checkBoxes = Array.from(this.element.querySelectorAll("input[type=checkbox]:not([disabled=disabled])"))
    this.checkBoxes.forEach((elem) => { elem.addEventListener('change', this._setOptOutCookie) })

    this._setOptOutCookie() // Ensure we have a default setting in place
  }

  disconnect() {
    this.acceptButtonTargets.forEach((elem) => { elem.removeEventListener('click', this.acceptAll) })
    this.rejectButtonTargets.forEach((elem) => { elem.removeEventListener('click', this.rejectAll) })
    this.closeButtonTargets.forEach((elem) => { elem.removeEventListener('click', this.closeCookieManagerPane) })
    this.checkBoxes.forEach((elem) => { elem.removeEventListener('change', this._setOptOutCookie) })
    super.disconnect()
  }

  closeCookieManagerPane(event) {
    if(event) {
      event.stopPropagation()
      event.preventDefault()
    }
    this.element.remove()
  }

  acceptAll(event) {
    if(event) {
      event.stopPropagation()
      event.preventDefault()
    }
    this.checkBoxes.forEach((elem) => {
      elem.checked = true
    })
    this._setOptOutCookie()
    this.closeCookieManagerPane()
  }

  rejectAll(event) {
    if(event) {
      event.stopPropagation()
      event.preventDefault()
    }
    this.checkBoxes.forEach((elem) => {
      elem.checked = false
    })
    this._setOptOutCookie()
    this.closeCookieManagerPane()
  }

  // When a form input is changed,
  _setOptOutCookie(event) {
    if(event) {
      event.stopPropagation()
    }
    // Names look like: cookie_manager[performance]. The slice pulls out the contents of the [].
    const uncheckedInputs = Array.from(this.element.querySelectorAll("input[type=checkbox]:not(:checked)"))
    if(uncheckedInputs.length == 0) {
      this._setPermanentCookie(this.cookieName, "none")
    } else {
      this._setPermanentCookie(this.cookieName, uncheckedInputs.map(elem => elem.getAttribute("name").slice(15, -1)).join("|"))
    }
  }

  // Low-level function to parse document.cookie and get a cookie's value
  _getCookie(name) {
    const allCookies = document.cookie
    let cookieValue = allCookies.split('; ').find((row) => row.startsWith(`${name}=`))?.split("=")[1]
    if(cookieValue) {
      return decodeURI(cookieValue).split('|')
    } else {
      return null
    }
  }

  // Low-level function to set a permanent cookie, defined as a 1-year validity period
  _setPermanentCookie(name, value, path = '/') {
    let expiration = new Date()
    expiration.setTime(expiration.getTime() + 365 * 24 * 60 * 60 * 1000) // 1 year in the future
    return this._setCookie(name, value, expiration, path)
  }

  // Low-level function to set a cookie
  _setCookie(name, value, expiration, path = '/') {
    document.cookie = `${name}=${value}; expires=${expiration.toGMTString()}; path=${path}`
  }
}

