import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['form']

  connect() {
    this.formTarget.querySelectorAll('[data-controller="autocomplete-multiselect"]').forEach(
      (multiselect) => {
        multiselect.addEventListener('autocompleteMultiselect.change', (ev)=>{ this.search() })
      }
    )
    this.saveUrlParams(this.getUrlParams())
  }

  search() {
    // Update the URL with our search (to make it bookmarkable)
    const newUrlParams = this.getUrlParams()
    // Don't run the search unless the params have changed
    if(newUrlParams != this.urlParams) {
      this.saveUrlParams(newUrlParams)
      history.pushState({}, '', '?' + newUrlParams)

      // Trigger the form submission in a way that Turbo will pick it up
      this.formTarget.dispatchEvent(new Event('form:submit')) // ensure event listeners will know the form is submitting
      this.formTarget.requestSubmit()
    }
  }

  reset() {
    this.application.getControllerForElementAndIdentifier(this.formTarget, 'form').reset()
    this.search()
  }

  getUrlParams() {
    return (new URLSearchParams(new FormData(this.formTarget))).toString()
  }

  saveUrlParams(newParams) {
    this.urlParams = newParams
  }
}
