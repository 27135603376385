import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['tab', 'pane']

  connect() {
    super.connect()
    let index = this.anchor
    if(index) {
      this.index = index
    } else {
      this.index = 0;
    }
  }

  // Respond to a user-click on a tab
  change(event) {
    event.preventDefault()
    this.index = this.tabTargets.indexOf(event.currentTarget)
  }

  // Show the active tab, hide the inactive ones
  showTab() {
    this.tabTargets.forEach((tab, index) => {
      const pane = this.paneTargets[index]

      if (index == this.index) {
        pane.classList.remove('hidden')
        tab.classList.remove('inactive')
        tab.classList.add('active')
      } else {
        pane.classList.add('hidden')
        tab.classList.remove('active')
        tab.classList.add('inactive')
      }
    })
  }

  // Get the index of the current tab
  get index() {
    return parseInt(this.data.get('index') || 0)
  }

  get storageKey() {
    return window.location.pathname
  }

  // Set the index of the current tab, and trigger the showTab() function to show the newly current tab
  set index(value) {
    this.data.set('index', value)
    this.anchor = value
    this.showTab()
  }

  // Retrieve the current tab from the URL anchor
  get anchor() {
    if(window.location.hash) {
      const string = window.location.hash.substring(1) // Strip the leading #
      if(string) {
        const match = string.match(/tab(\d?)/)
        if(match) {
          return (match[1] ? match[1] : null)
        }
      }
    } else {
      return window.localStorage.getItem(this.storageKey)
    }
    return null
  }

  // Set the current tab in the URL anchor
  set anchor(index) {
    window.location.hash = 'tab' + index
    window.localStorage.setItem(this.storageKey, index)
  }
}
