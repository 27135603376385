import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    teamIdParam: String
  }

  connect() {
    super.connect();
  }

  update(event) {
    event.preventDefault();
    const selectElement = event.currentTarget;
    const selectedValue = selectElement.value;
    const url = new URL(window.location);
    // Clear existing params
    url.searchParams.delete('id');
    // The all_users flag is used to expand out all users (instead of grouped by team)
    url.searchParams.delete('all_users');

    if (selectedValue === 'all_users') {
      url.searchParams.set('all_users', 'true');
    } else if (selectedValue) {
      url.searchParams.set('id', selectedValue);
      url.searchParams.delete('user_id'); // if you change teams, you don't want to keep the user param
    }

    window.location = url.toString();
  }
}