import ApplicationController from './application_controller'

// Controller for handling a DropdownComponent. Will fire dropdown:open and dropdown:close events when opened/closed.
export default class extends ApplicationController {
  static targets = ['toggle', 'pane']

  connect() {
    super.connect()
    if(this.hasToggleTarget) {
      this.toggleElement = this.toggleTarget
    } else {
      this.toggleElement = this.element
    }
    this._bindToggleHandler(this.toggleElement)
    this._documentClickHandler = this._documentClickHandler.bind(this)
    this.closePane()
  }

  _bindToggleHandler(element) {
    element.addEventListener('click', this.toggle.bind(this))
  }

  _bindDocumentClicks() {
    document.addEventListener('click', this._documentClickHandler)
  }

  _unbindDocumentClicks() {
    document.removeEventListener('click', this._documentClickHandler)
  }

  _documentClickHandler(event) {
    const clickedElement = event.target
    const closestDropdown = clickedElement.closest('dropdown')

    if(closestDropdown === this.element) {
      // No-op; let handling proceed as normal
    } else {
      // The click was outside this.element, so shut it down
      this.closePane()
    }
  }

  toggle() {
    if(this.status == 'open') {
      this.closePane()
    } else {
      this.openPane()
    }
  }

  openPane() {
    this._bindDocumentClicks()
    this.status = 'open'
    this.paneTarget.classList.remove('closed')
    this.paneTarget.classList.add('open');
    this.toggleElement.classList.remove('closed')
    this.toggleElement.classList.add('open')
    this.element.dispatchEvent(new Event('dropdown:open'))
  }

  closePane() {
    this._unbindDocumentClicks()
    this.status = 'closed'
    this.paneTarget.classList.remove('open')
    this.paneTarget.classList.add('closed')
    this.toggleElement.classList.remove('open')
    this.toggleElement.classList.add('closed')
    this.element.dispatchEvent(new Event('dropdown:close'))
  }
}
