import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['link']

  favoriteOrUnfavorite(event) {
    event.preventDefault()
    event.stopPropagation()
    if(this.linkTarget.innerText == 'Applied') {
      this.unfavorite()
    } else {
      this.favorite()
    }
  }

  favorite() {
    this.log("Favoriting Job ", this.linkTarget.dataset.jobId)
    fetch(this.linkTarget.dataset.favoritePath, {
      method: 'POST',
      headers: this.ajaxHeaders()
    }).then(response => {
      if(response.status == 200) {
        this.updateAllJobCardsTo('favorited')
      }
    })
  }

  unfavorite() {
    this.log("Unfavoriting Job ", this.linkTarget.dataset.jobId)
    fetch(this.linkTarget.dataset.unfavoritePath, {
      method: 'DELETE',
      headers: this.ajaxHeaders()
    }).then(response => {
      if(response.status == 200) {
        this.updateAllJobCardsTo('unfavorited')
      }
    })
  }

  updateAllJobCardsTo(favoriteStatus) {
    let selector = 'a[data-job-gid="' + this.linkTarget.dataset.jobGid + '"][data-job-interest-target="link"]'
    this.log("updating cards with selector", selector)
    document.querySelectorAll(selector).forEach(linkElement => {
      if(favoriteStatus == 'favorited') {
        linkElement.innerText = 'Applied'
        linkElement.classList.add('bordered')
      } else {
        linkElement.innerText = 'Apply'
        linkElement.classList.remove('bordered')
      }
    })
  }
}
