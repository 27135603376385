import ApplicationController from './application_controller'
import Croppie from 'croppie' // http://foliotek.github.io/Croppie/

// Simple controller for toggling a CSS class based on clicks. Makes no assumptions about starting state,
// so whatever the markup has will be toggled on click.
export default class extends ApplicationController {
  static targets = ['preview', 'input', 'base64input']
  static values = {
    maxWidth: Number,
    maxHeight: Number
  }

  connect() {
    const viewportRatio = this.maxWidthValue / this.maxHeightValue
    const viewportScalingFactor = (viewportRatio > 1 ? this.maxWidthValue / 200 : this.maxHeightValue / 200)
    const styles = getComputedStyle(this.element)
    const innerPadding = styles.getPropertyValue('--croppable-image-inner-padding')
    const viewportOptions = {
      width: `calc(${this.maxWidthValue / viewportScalingFactor}px - 2 * ${innerPadding})`,
      height: `calc(${this.maxHeightValue / viewportScalingFactor}px - 2 * ${innerPadding})`
    }

    this.croppie = new Croppie(this.previewTarget, {
      viewport: {
        ...viewportOptions,
        type: 'circle'
      },
      boundary: {
        ...viewportOptions
      }
    })

    this.base64inputTarget.value = null // Zero it out so we don't re-submit the data
    this.preprocessingComplete = true // Track if we've done the work.
    this.formTarget = this.inputTarget.closest('form')

    this.onInputChange = this.onInputChange.bind(this)
    this.inputTarget.addEventListener("change", this.onInputChange)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.formTarget.addEventListener("submit", this.onFormSubmit)
    this.instrumentDragAndDropEvents = this.instrumentDragAndDropEvents.bind(this)
    // Give croppie a chance to init so we don't automatically call the callback
    setTimeout(this.instrumentDragAndDropEvents, 250)
  }

  // Set up all of the drag & drop events
  instrumentDragAndDropEvents() {
    this.onCrop = this.onCrop.bind(this)
    this.highlightDropArea = this.highlightDropArea.bind(this)
    this.unhighlightDropArea = this.unhighlightDropArea.bind(this)
    this.onFileDrop = this.onFileDrop.bind(this)

    this.croppie.elements.zoomer.addEventListener('change', this.onCrop)

    this.element.addEventListener('dragenter', this.highlightDropArea)
    this.element.addEventListener('dragover',  this.highlightDropArea)
    this.element.addEventListener('dragleave', this.unhighlightDropArea)
    this.element.addEventListener('drop',      this.unhighlightDropArea)
    this.element.addEventListener('drop',      this.onFileDrop)
  }

  // If the crop is changed, we have work to do
  onCrop(event) {
    this.preprocessingComplete = false
  }

  highlightDropArea(event) {
    event.stopPropagation()
    event.preventDefault()
    this.inputTarget.classList.add('hover')
  }

  unhighlightDropArea(event) {
    event.stopPropagation()
    event.preventDefault()
    this.inputTarget.classList.remove('hover')
  }

  // If a file is dropped onto the croppie, pass it through to the file input
  onFileDrop(event) {
    event.stopPropagation()
    event.preventDefault()
    if(event.dataTransfer && event.dataTransfer.files) {
      this.bindToCroppie(event.dataTransfer.files[0])
    }
  }

  // If a new image is added to the input, we have work to do
  onInputChange(event) {
    this.preprocessingComplete = false

    let files = event.target.files

    if(files && files.length > 0) {
      this.bindToCroppie(files[0])
    }
  }

  bindToCroppie(file) {
    let reader = new FileReader()
    reader.onload = (event) => {
      this.croppie.bind(event.target.result) // Bind the image to croppie
    }
    reader.readAsDataURL(file)
  }

  onFormSubmit(event) {
    if(this.preprocessingComplete) {
      // In this case, we have done the pre-processing, so let the form submit
      return
    } else {
      // Halt the event so we can do the image pre-processing
      event.preventDefault()
      event.stopPropagation()
    }

    let croppedImage = this.croppie.result({
      type: 'base64',
      size: {
        width: this.maxWidth,
        height: this.maxHeight
      },
      format: 'jpeg',
      circle: false
    }) // returns a promise

    croppedImage.then(base64image => {
      this.inputTarget.disabled = true // Disable the original input so it doesn't send to the server

      // Now, resize the image to fit in the maxWidth and maxHeight
      let image = new Image()
      image.onload = ()=>{
        let ratio = 1
        if(image.width > this.maxWidthValue) {
          ratio = this.maxWidthValue / image.width
        } else if(image.height > this.maxHeightValue) {
          ratio = this.maxHeightValue / image.height
        }

        // We're done if we don't need to downsize
        if(ratio == 1) {
          this.completePreprocessing(base64image)
          return
        }

        let canvasCopy = document.createElement("canvas")
        let copyContext = canvasCopy.getContext("2d")

        canvasCopy.width = image.width * ratio
        canvasCopy.height = image.height * ratio
        copyContext.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvasCopy.width, canvasCopy.height)

        this.completePreprocessing(canvasCopy.toDataURL())
      }
      image.src = base64image
    })
  }

  completePreprocessing(base64image) {
    this.base64inputTarget.value = base64image;
    this.preprocessingComplete = true

    // Trigger the form submission in a way that Turbo will pick it up
    this.formTarget.dispatchEvent(new Event('form:submit')) // ensure event listeners will know the form is submitting
    this.formTarget.requestSubmit()
  }
}
