import ApplicationController from './application_controller'

// Simple controller for toggling a CSS class based on form select & checkbox elements. The exact behaviors are
// controlled with data attributes. The intent is to dynamically alter a form based on the user's inputs.
export default class extends ApplicationController {
  static values = {
    cssClass: String,
    triggerValues: Array // The values of the input that will add the cssClass
  }
  static targets = [
    'trigger', // The element that triggers the behavior
    'target' // The element that receives the cssClass
  ]

  connect() {
    if(this.triggerTarget.type && this.triggerTarget.type.toLowerCase() == 'select-one') {
      this.processSelectChange = this.processSelectChange.bind(this)
      this.triggerTarget.addEventListener('change', this.processSelectChange)
      this.processSelectChange()
    } else if(this.triggerTarget.type && this.triggerTarget.type.toLowerCase() == 'checkbox') {
      this.processCheckboxChange = this.processCheckboxChange.bind(this)
      this.triggerTarget.addEventListener('change', this.processCheckboxChange)
      this.processCheckboxChange()
    }
  }

  processCheckboxChange(e) {
    if(this.triggerValuesValue.includes('checked')) {
      if(this.triggerTarget.checked) {
        this._addCssClassValue()
      } else {
        this._removeCssClassValue()
      }
    } else {
      if(this.triggerTarget.checked) {
        this._removeCssClassValue()
      } else {
        this._addCssClassValue()
      }
    }
  }

  processSelectChange(e) {
    if(this.triggerValuesValue.includes(this.triggerTarget.value)) {
      this._addCssClassValue()
    } else {
      this._removeCssClassValue()
    }
  }

  _addCssClassValue() {
    this.targetTargets.forEach((target) => {target.classList.add(this.cssClassValue)})
  }

  _removeCssClassValue() {
    this.targetTargets.forEach((target) => {target.classList.remove(this.cssClassValue)})
  }
}
