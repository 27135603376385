import ApplicationController from './application_controller'
import Player from '@vimeo/player'

export default class extends ApplicationController {
  static values = {
    url: String,
    chapterId: String,
    codingProblemId: String,
    nextVideoUrl: String,
    userId: String,
    videoId: String
  }

  connect() {
    super.connect()

    // Set up callback functions with correct binding
    this._playCallback = this._playCallback.bind(this)
    this._playingCallback = this._playingCallback.bind(this)
    this._stopCallback = this._stopCallback.bind(this)

    // Initialize some data
    this.secondsViewed = 0
    this.instrumentationData = {}

    // Start the player
    const vimeoOptions = {
      url: this.urlValue,
      responsive: true
    }
    this.element.innerHTML = '' // Empty it out, to remove any "loading" content such as spinners
    this.vimeoPlayer = new Player(this.element.id, vimeoOptions)

    // Handle the instrumentation
    this._getInstrumentationData()
    this.vimeoPlayer.on('play', this._playCallback)
    this.vimeoPlayer.on('timeupdate', this._playingCallback)
    this.vimeoPlayer.on('pause', this._stopCallback)
    this.vimeoPlayer.on('ended', this._stopCallback)
  }

  _getInstrumentationData() {
    this.instrumentationData.videoUrl = this.urlValue
    this.instrumentationData.userId = this.hasUserIdValue ? this.userIdValue : 'r' + Math.floor(Math.random() * 10000)

    this.vimeoPlayer.getVideoId().then((id) => {
      this.instrumentationData.videoId = id
      this.instrumentationData.playbackId = `${this.userIdValue}_${id}_${Date.now()}`
    }).catch((error)=>{
      this.instrumentationData.videoId = this._errorInterpreter(error)
    })

    this.vimeoPlayer.getVideoTitle().then((title) => {
      this.instrumentationData.videoTitle = title
    }).catch((error)=>{
      this.instrumentationData.videoTitle = this._errorInterpreter(error)
    })

    Promise.all([this.vimeoPlayer.getVideoWidth(), this.vimeoPlayer.getVideoHeight()]).then((dimensions) => {
      this.instrumentationData.videoWidth = dimensions[0]
      this.instrumentationData.videoHeight = dimensions[1]
    })
  }

  _playCallback(data) {
    // data is { "duration": 61.857, "percent": 0.049, "seconds": 3.034 }
    this.instrumentationData.videoDuration ||= data.duration
    if(data.percent === 0 && window.newrelic) {
      window.newrelic.addPageAction('videoPlayStart', {...this.instrumentationData, "seconds": 0, "percentViewed": 0})
    }
  }

  // This callback gets triggered every ~250ms during playback. We have a max number of events of ~4/s
  // (collected as 120 events every 30s), so we want to drop many of these events
  _playingCallback(data) {
    // data is { "duration": 61.857, "percent": 0.049, "seconds": 3.034 }
    const viewed = Math.floor(data.seconds)
    if (viewed > this.secondsViewed) {
      this.secondsViewed = viewed
      if(this.hasChapterIdValue) {
        this.stimulate('VideoProgress#capture_education', this.chapterIdValue, data.seconds, data.percent)
      } else if(this.hasCodingProblemIdValue) {
        this.stimulate('VideoProgress#capture_coding_problem', this.codingProblemIdValue, data.seconds, data.percent)
      } else if(this.hasVideoIdValue) {
        this.stimulate('VideoProgress#capture_video', this.videoIdValue, data.seconds, data.percent)
      }
      if(window.newrelic) {
        window.newrelic.addPageAction('videoPlayContinue', {...this.instrumentationData, "seconds": data.seconds, "percentViewed": data.percent})

      }
    }
  }

  _stopCallback(data) {
    // data is { "duration": 61.857, "percent": 0.049, "seconds": 3.034 }
    if(this.hasNextVideoUrlValue && data.percent >= 0.99) {
      // Note: If you change the behavior here, also change it in the YoutubePlayerController
      const loadingScreen = this.element.parentElement.querySelector('#loading_screen')
      if(loadingScreen) {
        loadingScreen.classList.remove("hidden")
        setTimeout(() => Turbo.visit(this.nextVideoUrlValue), 2000)
      }
    }
    if(window.newrelic) {
      window.newrelic.addPageAction('videoPlayStop', {...this.instrumentationData, "seconds": data.seconds, "percentViewed": data.percent})
    }
  }

  _errorInterpreter(error) {
    switch (error.name) {
      case 'PrivacyError':
        return '[Redacted by privacy settings]'
        break;
      default:
        return `[Ommitted because of ${error.name}]`
        break;
    }
  }
}
