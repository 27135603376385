import ApplicationController from './application_controller'

// Simple controller for fading out an element. See the fade.scss file for animation details.
export default class extends ApplicationController {
  static values = {
    direction: {type: String, default: 'out'}
  }

  connect() {
    super.connect()

    if(this.directionValue == 'in') {
      this.element.addEventListener('animationend', ()=>{
        this.element.classList.add('faded_in')
        this.element.classList.remove('fade_in')
      })
      this.element.classList.add('fade_in')
    } else if(this.directionValue == 'out') {
      this.element.addEventListener('animationend', ()=>{
        this.element.remove()
      })
      this.element.classList.add('fade_out')
    } else {
      this.log("Don't understand how to fade ", this.directionValue)
    }
  }
}
