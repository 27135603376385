import ApplicationController from './application_controller'

// The controller's sole purpose is to ensure that trix-editor elements that get morphed will still work after the
// morph. Trix stores state in the DOM, and morphdom has a tendency to wipe out and replace the DOM element, which
// leaves the Trix JS unattached to the DOM, and the new DOM element devoid of JS behavior. Trix also doesn't provide
// a "re-init" or manual init method. The code here attempts to hack around that, so that if we morph a trix-editor it
// will still work after the morph. The code is also sensitive to the fact that not all trix-editors get morphed when a
// morph happens, and makes sure that unmorphed trix-editor elements continue to work.
export default class extends ApplicationController {
  connect() {
    super.connect()
    this._instrumentEvents()
  }

  disconnect() {
    this._uninstrumentEvents()
    super.disconnect()
  }

  _instrumentEvents() {
    this._beforeReflexCallback = this._beforeReflexCallback.bind(this)
    this._afterReflexCallback = this._afterReflexCallback.bind(this)
    document.body.addEventListener('stimulus-reflex:before', this._beforeReflexCallback)
    document.body.addEventListener('stimulus-reflex:after', this._afterReflexCallback)
  }

  _uninstrumentEvents() {
    document.body.removeEventListener('stimulus-reflex:before', this._beforeReflexCallback)
    document.body.removeEventListener('stimulus-reflex:after', this._afterReflexCallback)
  }

  _beforeReflexCallback() {
    // Save these for the afterReflexCallback()
    this.trixId = this.element.trixId
    this.toolbarElementId = this.element.getAttribute('toolbar')

    // Remove all of the state Trix stores in the DOM that lets Trix know the element is initialized
    this.element.disconnectedCallback()
    this.element.removeAttribute('data-trix-internal')
    this.element.removeAttribute('toolbar')
    this.element.removeAttribute('contenteditable')
    this.element.editorController = false
  }

  _afterReflexCallback() {
    // The conditional is asking if the trix-editor element was around before the reflex started
    if(this.trixId && this.toolbarElementId) {
      let toolbar = document.querySelector(`#${this.toolbarElementId}`)
      if(toolbar) {
        // We're about to ask Trix to re-init the toolbar, so we prevent double toolbars because ETOOMANYTOOLBARS
        toolbar.remove()
      }
      this.element.connectedCallback()
      this.element.toolbarElement
    }
  }
}
