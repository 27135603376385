import ApplicationController from './application_controller'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends ApplicationController {
  connect() {
    super.connect()
    this._instrumentChanges()
  }

  disconnect() {
    this.element.querySelectorAll('select').forEach((elem)=>{
      elem.removeEventListener('change', this.processChangeHandler)
    })
  }

  processChange(event) {
    Turbo.visit(this._destinationUrl(event.target))
  }

  _instrumentChanges() {
    this.processChangeHandler = this.processChange.bind(this)
    this.element.querySelectorAll('select').forEach((elem)=>{
      elem.addEventListener('change', this.processChangeHandler)
    })
  }

  _destinationUrl(element) {
    let url = new URL(window.location.href)
    var paramName = element.name.substring(
        element.name.lastIndexOf("[") + 1,
        element.name.lastIndexOf("]")
    )
    url.searchParams.set(paramName, element.value)
    return url
  }
}
