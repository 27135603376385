import ApplicationController from './application_controller'

// Enables the search-to-add-an-item functionality for a listOrderingController implementation. When you search in the
// input, this uses the provided template to add another element to the DOM representing the item that was just
// searched for.
export default class extends ApplicationController {
  static targets = ["template", "searchInput", "items"]

  connect() {
    super.connect()

    this.changeHandler = this.changeHandler.bind(this)
    this.template = this.templateTarget
    this.searchInput = this.searchInputTarget
    this.itemsContainer = this.itemsTarget

    this.searchInput.closest('[data-controller=autocomplete-select]').addEventListener('autocomplete.change', this.changeHandler)
  }

  disconnect() {
    super.disconnect()
    this.searchInput.closest('[data-controller=autocomplete-select]').removeEventListener('autocomplete.change', this.changeHandler)
  }

  changeHandler(event) {
    // Grab the event data
    const itemId = event.detail.value
    const itemName = event.detail.textValue

    if(this.element.querySelector(`item[data-add-an-item-item-id-value="${itemId}"]`) == null) {
      // Clone the template
      const clone = this.template.content.cloneNode(true)

      // Update the elements in the clone with the event data
      const item = clone.querySelector('item')
      item.setAttribute('id', item.getAttribute('id') + itemId)
      item.setAttribute('data-add-an-item-item-id', itemId)
      item.setAttribute('data-list-ordering-id-number-value', itemId)
      clone.querySelector('span').textContent = itemName
      const hiddenInput = clone.querySelector('input[type=hidden]')
      hiddenInput.setAttribute('id', hiddenInput.getAttribute('id') + itemId)
      hiddenInput.setAttribute('value', itemId)

      // Add the clone to the item list
      this.itemsContainer.appendChild(clone)
    }

    // Empty out the search field
    this.searchInput.value = ""
  }
}
