import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'

/* This is your ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends Controller {
  connect () {
    StimulusReflex.register(this)
  }

  // Debugging assistance
  log(...messages) {
    let identity = "[" + this.identifier + ": " + this.element.localName
    if(this.element.id) {
      identity = identity + "#" + this.element.id + "]"
      console.log(identity, ...messages)
    } else {
      identity = identity + "]"
      console.log(identity, this.element, ...messages)
    }
  }

  // POST, PATCH, and DELETE calls need the X-CSRF-Token header to be set, but in test the token
  // doesn't exist. This function DRYs up the logic for getting the token.
  ajaxHeaders() {
    let tokenElement = document.head.querySelector('meta[name="csrf-token"]')
    if(tokenElement) {
      return {
        "X-CSRF-Token": tokenElement.getAttribute('content')
      }
    } else {
      return {}
    }
  }

  /* Application-wide lifecycle methods
   *
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Example#demo"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  beforeReflex (element, reflex, noop, reflexId) {
    document.body.classList.add('wait')
  }

  reflexSuccess (element, reflex, noop, reflexId) {
    // show success message
  }

  reflexError (element, reflex, error, reflexId) {
    console.warn("Reflex error: ", error)
    console.warn("  reflex: ", reflex)
  }

  reflexHalted (element, reflex, error, reflexId) {
    console.warn("Reflex halted: ", error)
    console.warn("  reflex: ", reflex)
  }

  reflexForbidden (element, reflex, noop, reflexId) {
    console.warn('Reflex authentication failure.', reflex)
  }

  afterReflex (element, reflex, noop, reflexId) {
    document.body.classList.remove('wait')
  }

  finalizeReflex (element, reflex, noop, reflexId) {
    // all operations have completed, animation etc is now safe
  }
}
