import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["ignoreClicks"]
  static values = {
    markAsSelectedOnClick: Boolean, // Defaults to true
    ping: String
  }

  connect() {
    super.connect()

    this.markAsSelectedOnClick = this.hasMarkAsSelectedOnClickValue ? this.markAsSelectedOnClickValue : true

    const magicLink = this.element.querySelector('.magic_link')
    if(magicLink) {
      this.magicLink = magicLink
    } else {
      this.magicLink = document.createElement('a')
      this.magicLink.href = this.element.dataset.href
      if(this.element.attributes.target) {
        this.magicLink.dataset.turboFrame = this.element.attributes.target.nodeValue
      } else {
        if(this.element.dataset.turboFrame) {
          this.magicLink.dataset.turboFrame = this.element.dataset.turboFrame
        }
        if(this.element.dataset.turboAction) {
          this.magicLink.dataset.turboAction = this.element.dataset.turboAction
        }
        if(this.element.dataset.method) {
          this.magicLink.setAttribute('method', this.element.dataset.method)
        }
      }
      this.magicLink.classList = 'magic_link hidden'
      this.element.prepend(this.magicLink)
    }
  }

  openDetail(event) {
    const clickedElement = event.target
    const interactiveElements = ['a', 'button', 'input', 'textarea', 'select', 'dropdown', 'toggle']

    // Filter out clicks on active links, form elements, and targets where we ignore clicks; we only want clicks on the card
    if((clickedElement.disabled || !interactiveElements.includes(clickedElement.localName)) && !clickedElement.closest("[data-card-target=ignoreClicks]")) {
      event.stopPropagation()
      event.preventDefault()

      this.markCardAsSelected()

      if(this.hasPingValue) {
        fetch(this.pingValue, {method: 'POST'}) // Telemetry collection: fire and forget
      }

      // If the card opens a modal, make sure we know when the modal closes so we can handle history correctly
      if(this.magicLink.dataset.turboFrame == 'modal') {
        document.querySelector('#modal')
          .addEventListener('modal:closed', this.modalCloseHandler.bind(this), {once: true})
      }

      // Non-same-origin links can't be pushed onto the state, and should be opened in a new window anyway
      // So rather than parsing links, if the pushState() call throws an error, we catch it go to new window
      try {
        history.pushState({}, '', this.element.dataset.historyHref || this.element.dataset.href)
      }
      catch(err) {
        this.magicLink.target = '_blank'
        this.magicLink.setAttribute('aria-label', 'opens in a new window')
      }
      this.magicLink.click()
    }
  }

  markCardAsSelected() {
    if(this.markAsSelectedOnClick) {
      const selectedSibling = this.element.parentElement.querySelector(this.element.tagName + '.selected')
      if(selectedSibling) {
        selectedSibling.classList.remove('selected')
      }
      this.element.classList.add('selected')
    }
  }

  deselectCard() {
    this.element.classList.remove('selected')
  }

  modalCloseHandler() {
    this.deselectCard()
    history.back()
  }
}

