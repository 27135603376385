import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['icon']

  connect() {
    this.setHintText(this.element)
  }

  setHintText(element) {
    const hint = this.contacted ? `You have already talked to ${this.candidateName}. Reach out to ${this.candidateObjectPronoun} again?` : `Start a conversation with ${this.candidateName}?`
    element.setAttribute('title', hint)
  }

  confirmationMessage() {
    let message = ''

    if(this.contacted) {
      message += `Are you sure you want to reach out to ${this.candidateName} again? It looks like someone in your company already has.`
    } else {
      message += `Are you interested in reaching out to ${this.candidateName} for ${this.jobId ? 'this' : 'a'} role?`
    }

    message += `\n\nIf you are interested, we'll send an email on your behalf ${this.jobId ? 'referencing this job posting ' : ''}and ask ${this.candidateObjectPronoun} to respond to start the interview process!`

    if(!this.jobId) {
      message += `\n\nIf you're interested in ${this.candidateName} for a particular role you can reach out to ${this.candidateObjectPronoun} from the jobs section of the YearOne platform, and we can include the job details in the initial email.`
    }
    return message
  }

  get contacted() {
    return this.element.classList.contains('contacted')
  }

  get candidateName() {
    return this.element.dataset.candidateFirstName
  }

  get candidateObjectPronoun() {
    return this.element.dataset.candidateObjectPronoun
  }

  get jobId() {
    return this.element.dataset.jobId
  }

  createContact(event) {
    event.stopPropagation()
    event.preventDefault()

    // JS confirm
    if(!confirm(this.confirmationMessage())) {
      return
    }

    this.ping()

    // trigger the job
    this.log("Sending contact email to candidate", this.element.dataset.candidateId)
    fetch(this.element.href, {
      method: 'POST',
      headers: this.ajaxHeaders()
    }).then(response => {
      if(response.status == 200) {
        if(this.element.dataset.customerEmail) {
          alert(`We have reached out. ${this.candidateName} will get back to you by email at ${this.element.dataset.customerEmail}.`)
        } else {
          alert(`We have reached out. ${this.candidateName} will get back to you by email.`)
        }
        this.markAllCardsAsContacted()
      } else {
        alert("Something went wrong and we couldn't reach out! Please try again and contact support@joinyearone.io if the problem persists.")
      }
    })
  }

  // send a tracking ping to the server
  ping() {
    if(this.element.dataset.pingUrl) {
      fetch(this.element.dataset.pingUrl, {
        method: 'POST',
        headers: {...this.ajaxHeaders(), 'ping-from': window.location.href, 'ping-to': this.element.href}
      }).then(response => { this.log('ping complete') })
    }
  }

  // Flip the toggle in the UI, for all instances of the candidate card.
  // This isn't a real toggle -- it's one-way -- so the logic is relatively simple.
  markAllCardsAsContacted() {
    let selector = 'a[data-controller="candidate-conversation"][data-candidate-id="' + this.element.dataset.candidateId + '"]'
    this.log("updating cards with selector", selector)

    document.querySelectorAll(selector).forEach(linkElement => {
      linkElement.classList.add('contacted')
      linkElement.children[0].classList.add('inverted')
      this.setHintText(linkElement)
    })
  }
}
