import ApplicationController from './application_controller'
import throttle from "lodash.throttle"

// Stimulus controller for governing the behavior of disclosure panes. There are two targets, representing the element
// that is the toggle (the thing you click to open/close the pane), and the pane itself. The controller accepts
// some values: "open" which is set to true/false and determines the default open/closed state of the pane, and
// "storageKey" which determines the localStorage key used to track the open/closed state.
//
// The widget will default to being open the first time a user sees it, then closed on subsquent views. If the user
// interacts with the widget, the state they set (open/closed) will be preserved in the localStorage key.
//
// If the storageKey is set to "false" (the string, not the boolean!) then the state will not be stored, and subsequent
// page loads will always be in the default state as specified by the "open" value.
export default class extends ApplicationController {
  static targets = ['toggle', 'pane']
  static values = {
    open: Boolean,
    storageKey: String,
    forceOpen: Boolean
  }

  connect() {
    super.connect()
    if(this.hasToggleTarget) {
      this.toggleElement = this.toggleTarget
    } else {
      this.toggleElement = this.element
    }
    this.toggle = this.toggle.bind(this)
    this.adjustHeight = throttle(this.adjustHeight.bind(this), 150)
    this.toggleElement.addEventListener('click', this.toggle)
    window.addEventListener('resize', this.adjustHeight)
    this.storageKeyValue ||= window.location.href
    this.forceOpen = this.element.dataset.disclosureForceOpen || false

    // Set the default open/closed state. First we see if there's a saved state, and if so, we honor it.
    // Failing that, we check if there is an instruction. If there is, we honor it. If not, it's the first load,
    // so we default to open, but set the state to closed so it's there on the next page load.
    if(this.savedState() != null) {
      // with a closed save state + open value, we force it open
      if(this.savedState() == 'open' || this.forceOpen) {
        this.openPane()
      } else {
        this.closePane()
      }
    } else if(this.hasOpenValue) {
      if (this.openValue) {
        this.openPane()
      } else {
        this.closePane()
      }
    } else {
      // First-time load: default to open, but set it to be closed next time
      this.openPane()
      this.saveState('closed')
    }
  }

  disconnect() {
    this.toggleElement.removeEventListener('click', this.toggle)
    window.removeEventListener('resize', this.adjustHeight)
  }

  savedState() {
    if(this.storageKeyValue === 'false') {
      return null
    } else {
      return window.localStorage.getItem(this.storageKeyValue)
    }
  }

  saveState(value) {
    if(this.storageKeyValue !== 'false') {
      window.localStorage.setItem(this.storageKeyValue, value)
    }
    return value
  }

  toggle(event) {
    const clickedElement = event.target
    const interactiveElements = ['a', 'button', 'input', 'textarea', 'select', 'dropdown']

    // Filter out clicks on active links and form elements; we only want clicks on the toggle
    if(clickedElement.disabled || !interactiveElements.includes(clickedElement.localName)) {
      if(this.status === 'open') {
        this.closePane()
      } else {
        this.openPane()
      }
    }
    event.preventDefault()
    event.stopPropagation()
  }

  openPane() {
    this.status = 'open'
    this.paneTargets.forEach((paneTarget) => {paneTarget.classList.remove('closed')})
    this.paneTargets.forEach((paneTarget) => {paneTarget.classList.add('open')});
    this.toggleElement.classList.remove('closed')
    this.toggleElement.classList.add('open')
    this.adjustHeight()
    this.saveState('open')
  }

  closePane() {
    this.status = 'closed'
    this.paneTargets.forEach((paneTarget) => {paneTarget.classList.remove('open')})
    this.paneTargets.forEach((paneTarget) => {paneTarget.classList.add('closed')})
    this.toggleElement.classList.remove('open')
    this.toggleElement.classList.add('closed')
    this.adjustHeight()
    this.saveState('closed')
  }

  adjustHeight() {
    if(this.status === 'open') {
      this.paneTargets.forEach((paneTarget) => {paneTarget.style.height = this.paneTarget.scrollHeight + 'px'})
    } else {
      this.paneTargets.forEach((paneTarget) => {paneTarget.style.height = 0 + 'px'})
    }
  }
}
