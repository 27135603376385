import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    id: String,
    chapterId: String,
    nextVideoUrl: String,
    origin: String,
    userId: String,
    videoId: String
  }

  connect() {
    super.connect()

    // Configure YouTube
    window.YTConfig ||= {}
    window.YTConfig['host'] = 'https://www.youtube-nocookie.com'

    // Set up callback functions with correct binding
    this._playingCallback = this._playingCallback.bind(this)
    this._readyCallback = this._readyCallback.bind(this)
    this._stateChangeCallback = this._stateChangeCallback.bind(this)
    this._initYouTube = this._initYouTube.bind(this)

    // Initialize some data
    this.duration = 0
    this.secondsViewed = 0
    this.instrumentationData = {}
    this.initialized = false

    // Set up the instrumentation interval
    this.playbackInterval = null

    if(window.onYouTubeIframeAPIReady) {
      // This is the second video on the page, so the usual YT init stuff doesn't work (the magical
      // onYouTubeIframeAPIReady function has already been called), so we must init by hand
      this._initYouTube()
    } else {
      // Start the player. This is a magic-name function; the YouTube script invokes it.
      this._youTubeCallback = this._youTubeCallback.bind(this)
      window.onYouTubeIframeAPIReady = this._youTubeCallback
    }
  }

  _youTubeCallback() {
    this.initialized = true
    this.youtubePlayer = new YT.Player(this.element.id, {
      height: this.element.offsetHeight,
      host: 'https://www.youtube-nocookie.com',
      videoId: this.idValue,
      width: this.element.offsetWidth,
      playerVars: {
        'modestbranding': 1,
        'origin': this.originValue,
        'playsinline': 1
      },
      events: {
        'onReady': this._readyCallback,
        'onStateChange': this._stateChangeCallback
      }
    })
  }

  // When the current video is the *second* video to be shown on the page, eg after a nav event from the
  // chapters playlist, window.onYouTubeIframeAPIReady() isn't called again, because the calling script
  // has already been loaded. However, it appears that the YT.PlayerState doesn't exist when the first
  // video is loaded. So we check to see if YT.PlayerState exists; if it does, we assume we're at a video
  // after the first, and that we should call window.onYouTubeIframeAPIReady() by hand since YouTube is
  // incapable of functioning on the modern web.
  _initYouTube() {
    if(this.initialized) {
      return true
    } else if(window.YT && window.YT.PlayerState) {
      window.onYouTubeIframeAPIReady = this._youTubeCallback.bind(this)
      this._youTubeCallback()
    } else {
      setTimeout(this._initYouTube, 50)
    }
  }

  _getInstrumentationData() {
    this.instrumentationData.videoId = this.idValue
    this.instrumentationData.userId = this.hasUserIdValue ? this.userIdValue : 'r' + Math.floor(Math.random() * 10000)
    this.instrumentationData.playbackId = `${this.userIdValue}_${this.idValue}_${Date.now()}`
    this.instrumentationData.videoDuration = this.youtubePlayer.playerInfo.duration
    this.instrumentationData.videoTitle = this.youtubePlayer.getVideoData().title
    this.instrumentationData.videoWidth = this.element.offsetWidth
    this.instrumentationData.videoHeight = this.element.offsetHeight
  }

  _readyCallback(event) {
    this._getInstrumentationData()
  }

  _stateChangeCallback(event) {
    if(event.data === YT.PlayerState.PLAYING) {
      if (this.duration === 0) {
        this.duration = this.youtubePlayer.playerInfo.duration
        this.instrumentationData.videoDuration = this.duration
        if(window.newrelic) {
          window.newrelic.addPageAction('videoPlayStart', {...this.instrumentationData, "seconds": 0, "percentViewed": 0})
        }
      }
      this._startInstrumentationInterval()
    } else if(event.data === YT.PlayerState.PAUSED || event.data === YT.PlayerState.ENDED) {
      this._stopInstrumentationInterval()
      this._stopCallback()
    }
  }

  _startInstrumentationInterval() {
    this.playbackInterval = setInterval(this._playingCallback, 1000)
  }

  _stopInstrumentationInterval() {
    clearInterval(this.playbackInterval)
  }

  // This callback gets triggered every 1s during playback.
  _playingCallback() {
    const secondsViewed = this.youtubePlayer.playerInfo.currentTime
    const percentViewed = secondsViewed / this.duration
    if (secondsViewed > this.secondsViewed) {
      this.secondsViewed = secondsViewed
      if(this.hasChapterIdValue) {
        this.stimulate('VideoProgress#capture_education', this.chapterIdValue, secondsViewed, percentViewed)
      }
      if(this.hasVideoIdValue) {
        this.stimulate('VideoProgress#capture_video', this.videoIdValue, secondsViewed, percentViewed)
      }
      if(window.newrelic) {
       window.newrelic.addPageAction('videoPlayContinue', {...this.instrumentationData, "seconds": secondsViewed, "percentViewed": percentViewed})

      }
    }
  }

  _stopCallback() {
    const secondsViewed = this.youtubePlayer.playerInfo.currentTime
    const percentViewed = secondsViewed / this.duration
    if(this.hasNextVideoUrlValue && percentViewed >= 0.99) {
      // Note: If you change the behavior here, also change it in the VimeoPlayerController
      const loadingScreen = this.element.parentElement.querySelector('#loading_screen')
      if(loadingScreen) {
        loadingScreen.classList.remove("hidden")
        setTimeout(() => Turbo.visit(this.nextVideoUrlValue), 2000)
      }
    }
    if(window.newrelic) {
      window.newrelic.addPageAction('videoPlayStop', {...this.instrumentationData, "seconds": secondsViewed, "percentViewed": percentViewed})
    }
  }
}
