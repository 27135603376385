import ApplicationController from './application_controller'

// Simple controller for toggling a CSS class based on clicks. Makes no assumptions about starting state,
// so whatever the markup has will be toggled on click.
export default class extends ApplicationController {
  static targets = ['input']

  toggleTarget() {
    this.inputTarget.click()
  }

  toggleTargetIfChecked() {
    if(this.element.checked) {
      this.toggleTarget()
    }
  }

  uncheckTargetIfChecked(e) {
    if(e.target.checked && this.inputTarget.checked) {
      this.toggleTarget()
    }
  }
}
