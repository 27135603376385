import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    userIdParam: String
  }

  connect() {
    super.connect();
  }

  update(event) {
    event.preventDefault();
    const selectElement = event.currentTarget;
    const userIdParam = selectElement.value || this.userIdParamValue;
    const url = new URL(window.location);
    if (userIdParam) {
      url.searchParams.set('user_id', userIdParam);
    } else {
      url.searchParams.delete('user_id'); // all teams = no param
    }
    window.location = url.toString();
  }
}