import ApplicationController from './application_controller'

// Make links in rich text areas open in a new tab/window
export default class extends ApplicationController {
  connect() {
    super.connect()
    this.log("element: ", this.element)
    this.element.querySelectorAll('a').forEach(function(link) {
      if (link.host !== window.location.host) {
        link.target = "_blank"
        link.setAttribute('aria-label', 'opens in a new window')
      }
    })
  }
}
