import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    userName: String,
    videoChatUrl: String,
    videoParticipantToken: String,
    eventEndTime: Number,
    themeJson: String
  }

  connect() {
    super.connect()

    this.videoChatContainer = document.getElementById('video_chat')
    if(this.videoChatContainer) {
      if(window.DailyIframe) {
        this.log("creating frame immediately")
        this.createDailyFrame()
      } else {
        this.log("delaying creating frame")
        setTimeout(this.createDailyFrame.bind(this), 5000)
      }
    }
  } // connect()

  createDailyFrame() {
    this.videoChatContainer.innerHTML = ''
    const dailyOptions = {
      showLeaveButton: true,
      showFullscreenButton: true,
      userName: this.userNameValue,
      url: this.videoChatUrlValue,
      theme: JSON.parse(this.themeJsonValue)
    }
    let callFrame = window.DailyIframe.createFrame(this.videoChatContainer, dailyOptions)

    callFrame
      .join({token: this.videoParticipantTokenValue})
      .then(participants => this.videoChatContainer.dispatchEvent(new CustomEvent('videoChatJoined', {bubbles: true})) )
      .catch(error => console.log("ERROR", error))

    callFrame.on('left-meeting', (event) => {
      const goodbyeContainer = document.getElementById('goodbye_message')
      if(goodbyeContainer) {
        this.videoChatContainer.classList.add('hidden')
        goodbyeContainer.classList.remove('hidden')
      }
      this.videoChatContainer.dispatchEvent(new CustomEvent('videoChatLeft', {bubbles: true}) )
    })
    let closeInterval = (new Date(this.eventEndTimeValue) - new Date)
    setTimeout(() => { location.reload() }, closeInterval); // Refresh the page when the room shuts

  }
}
