import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    timeWindowParam: String
  }

  connect() {
    super.connect();
  }

  update(event) {
    event.preventDefault();
    const timeWindowParam = event.currentTarget.dataset.timeWindowParam || this.timeWindowParamValue;
    const url = new URL(window.location);
    url.searchParams.set('tw', timeWindowParam);
    window.location = url.toString();
  }
}