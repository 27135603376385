import ApplicationController from './application_controller'
import throttle from "lodash.throttle"

// Controller for handling a Carousel, which is a modified card grid.
export default class extends ApplicationController {
  static values = {
    throttleDuration: {type: Number, default: 150}
  }

  connect() {
    super.connect()
    this.cardGrid = this.element.querySelector('.card_grid')
    this.createExtraMarkup()
    this.instrumentNavigation()
    this.toggleScrollButtonStatus()
    this.prepareForResizing()
  }

  // Generate the custom markup the carousel needs, for prev & next buttons
  createExtraMarkup() {
    this.element.setAttribute('aria-label', 'carousel')
    this.element.setAttribute('role', 'contentinfo')
    this.cardGrid.classList.add('carousel')

    this.prevScrollButton = document.createElement('div')
    this.prevScrollButton.setAttribute('aria-label', 'scroll carousel left')
    this.prevScrollButton.setAttribute('role', 'button')
    this.prevScrollButton.setAttribute('tabindex', 0)
    this.prevScrollButton.classList.add('carousel-prev')
    this.element.appendChild(this.prevScrollButton)

    this.nextScrollButton = document.createElement('div')
    this.nextScrollButton.setAttribute('aria-label', 'scroll carousel right')
    this.nextScrollButton.setAttribute('role', 'button')
    this.nextScrollButton.setAttribute('tabindex', 0)
    this.nextScrollButton.classList.add('carousel-next')
    this.element.appendChild(this.nextScrollButton)
  }

  // Instrument the prev & next buttons so we can handle their clicks
  instrumentNavigation() {
    this.scrollPrev = this.scrollPrev.bind(this)
    this.scrollNext = this.scrollNext.bind(this)
    this.toggleScrollButtonStatus = this.toggleScrollButtonStatus.bind(this)

    this.prevScrollButton.addEventListener('click', this.scrollPrev)
    this.nextScrollButton.addEventListener('click', this.scrollNext)
    this.cardGrid.addEventListener('scroll', throttle(this.toggleScrollButtonStatus, this.throttleDurationValue))
  }

  // Calculate the width of the viewport
  viewportWidth() {
    return this.element.offsetWidth
  }

  // Calculate the width of the carousel content
  totalWidth() {
    return this.cardGrid.scrollWidth
  }

  // Based on the relative widths of the content & viewport, do we even need a carousel?
  carouselIsNeeded() {
    return this.viewportWidth() < this.totalWidth()
  }

  // Setup
  prepareForResizing() {
    this.enableOrDisable = this.enableOrDisable.bind(this)
    window.addEventListener('resize', throttle(this.enableOrDisable, this.throttleDurationValue))
    this.enableOrDisable()
  }

  // Event handler that decides if the carousel is needed (based on content width & viewport width), and toggles
  // the carousel on or off accordingly.
  enableOrDisable(event) {
    if(this.carouselIsNeeded()) {
      if(this.element.classList.contains('disabled')) {
        this.element.classList.remove('disabled')
        this.cardGrid.scrollTo({left: 0, behavior: 'smooth'})
        this.toggleScrollButtonStatus()
      }
    } else {
      this.element.classList.add('disabled')
    }
  }

  // Calculate the width of a card plus the grid cap. Get it from the CSS.
  cardWidthWithGap() {
    const card = this.cardGrid.querySelector('.card')
    const columnGap = parseInt(getComputedStyle(this.cardGrid).getPropertyValue('column-gap').split('px')[0]);
    return card.offsetWidth + columnGap
  }

  // Scroll one card to the left
  scrollPrev() {
    const card = this.cardGrid.querySelector('.card')
    this.cardGrid.scrollTo({left: this.cardGrid.scrollLeft - this.cardWidthWithGap(), behavior: 'smooth'})
  }

  // Scroll one card to the right
  scrollNext() {
    const card = this.cardGrid.querySelector('.card')
    this.cardGrid.scrollTo({left: this.cardGrid.scrollLeft + this.cardWidthWithGap(), behavior: 'smooth'})
  }

  // Switch the prev/next buttons on or off depending on the scroll location
  toggleScrollButtonStatus() {
    if(this.cardGrid.scrollLeft == 0) {
      this.prevScrollButton.classList.add('disabled')
    } else {
      this.prevScrollButton.classList.remove('disabled')
    }
    if(this.cardGrid.scrollLeft + this.viewportWidth() >= this.totalWidth()) {
      this.nextScrollButton.classList.add('disabled')
    } else {
      this.nextScrollButton.classList.remove('disabled')
    }
  }
}
