import ApplicationController from './application_controller'

// Simple controller for toggling a CSS class based on clicks. Makes no assumptions about starting state,
// so whatever the markup has will be toggled on click.
export default class extends ApplicationController {
  static targets = ['preview', 'input']
  static values = {
    width: Number,
    height: Number
  }

  connect() {
    this.onInputChange = this.onInputChange.bind(this)
    this.inputTarget.addEventListener("change", this.onInputChange)
    this.instrumentDragAndDropEvents()
  }

  // Set up all of the drag & drop events
  instrumentDragAndDropEvents() {
    this.highlightDropArea = this.highlightDropArea.bind(this)
    this.unhighlightDropArea = this.unhighlightDropArea.bind(this)
    this.showFileDropped = this.showFileDropped.bind(this)

    this.inputTarget.addEventListener('dragenter', this.highlightDropArea)
    this.inputTarget.addEventListener('dragover',  this.highlightDropArea)
    this.inputTarget.addEventListener('dragleave', this.unhighlightDropArea)
    this.inputTarget.addEventListener('drop',      this.unhighlightDropArea)
    this.inputTarget.addEventListener('drop',      this.showFileDropped)
  }

  highlightDropArea(event) {
    this.element.classList.add('hover')
    this.inputTarget.classList.add('hover')
  }

  unhighlightDropArea(event) {
    this.element.classList.remove('hover')
    this.inputTarget.classList.remove('hover')
  }

  showFileDropped(event) {
    this.inputTarget.classList.add('attached')
  }

  // If a new image is added to the input, change the preview
  onInputChange(event) {
    if(this.inputTarget.files.length >= 1) {
      this.previewTarget.innerHTML = `<i class="fa-thin fa-paperclip" style="max-width: ${this.widthValue}px; max-height: ${this.heightValue}px"></i>`
    }
  }
}
