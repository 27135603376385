import ApplicationController from './application_controller'
import debounce from "lodash.debounce"

export default class extends ApplicationController {
  static targets = ["input", "toggle"]

  connect() {
    this.clearSearch = this.clearSearch.bind(this)
    this.onKeydown = debounce(this.onKeydown.bind(this), 300)
    if(this.hasInputTarget) {
      this.inputTarget.addEventListener("keydown", this.onKeydown)
    }
    if(this.hasToggleTarget) {
      this.toggleTarget.addEventListener("click", this.clearSearch)
    }
  }

  disconnect() {
    if(this.hasInputTarget) {
      this.inputTarget.removeEventListener("keydown", this.onKeydown)
    }
    if (this.hasToggleTarget) {
      this.toggleTarget.removeEventListener("click", this.clearSearch)
    }
  }

  onKeydown(event) {
    if(event.key == "Escape") {
      event.stopPropagation()
      event.preventDefault()
      this.clearSearch()
    }
  }

  clearSearch() {
    this.inputTarget.value = null;

    if(this.hasInputTarget) {
      let inputForm = this.inputTarget.closest('form')
      if(inputForm) {
        // Trigger the form submission in a way that Turbo will pick it up
        inputForm.dispatchEvent(new Event('form:submit')) // ensure event listeners will know the form is submitting
        inputForm.requestSubmit()
      }
    }

  }
}
