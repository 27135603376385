import ApplicationController from './application_controller'

// Controller for rebroacasting reflexes in the case of a websocket reconnect
export default class extends ApplicationController { 
  // reflex to rerun
  static values = {reflex: String}

  // Listen for the "reconnected" event
  connect() {
    super.connect()
    this.onReconnect = this.onReconnect.bind(this)
    document.addEventListener('reconnected', this.onReconnect)
  }

  disconnect() {
    document.removeEventListener('reconnected', this.onReconnect)
    super.disconnect()
  }

  // Re-run the provided reflex, if the page contains a loading class
  onReconnect() {
    this.log("reconnect")
    if(this.element.classList.contains('loading')) {
      this.stimulate(this.reflexValue)
    }
    document.removeEventListener('reconnected', this.onReconnect)
  }
}
