import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    eventAttendanceId: Number,
    rating: Number
  }

  connect() {
    super.connect()

    this._clickHandler = this._clickHandler.bind(this)

    this._instrumentClicks();
  }

  afterReflex() {
    this._instrumentClicks();
  }

  _instrumentClicks() {
    this.element.querySelectorAll('i.fa-thin').forEach((elem) => {
      elem.addEventListener('click', this._clickHandler)
    })
  }

  _clickHandler(event) {
    if(event.target.classList.contains('rating')) return true

    let stimulateOptions = {
      eventAttendanceId: this.eventAttendanceId,
      rating: event.target.dataset.rating
    }
    this.stimulate('EventAttendance#rate!', stimulateOptions)
  }
}
