import ApplicationController from './application_controller'

// When wrapped around a time zone <select> form element, if the element doesn't have a selected value, this controller
// will use the browser's configured time zone to choose one.
export default class extends ApplicationController {
  connect() {
    super.connect()

    if(this.element.querySelectorAll('option[selected]').length == 0) {
      this.element.querySelector(`option[value="${Intl.DateTimeFormat().resolvedOptions().timeZone}"]`).setAttribute("selected", "selected")
    }
  }
}
