import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    departmentIdParam: String
  }

  connect() {
    super.connect();
  }

  update(event) {
    event.preventDefault();
    const selectElement = event.currentTarget;
    const departmentIdParam = selectElement.value || this.departmentIdParamValue;
    const url = new URL(window.location);
    if (departmentIdParam) {
      url.searchParams.set('department_id', departmentIdParam);
      url.searchParams.delete('id'); // if you change departments, you don't want to keep the team param
      url.searchParams.delete('user_id'); // if you change departments, you don't want to keep the user param
    } else {
      url.searchParams.delete('department_id'); // all departments = no param
    }
    window.location = url.toString();
  }
}