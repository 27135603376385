import ApplicationController from './application_controller'

export default class extends ApplicationController {
  join(event) {
    sessionStorage.setItem(this.storageKey, Date.now())
    this.stimulate('EventAttendance#attended!')
  }

  leave(event) {
    const startTime = sessionStorage.getItem(this.storageKey)
    const durationInMilliseconds = startTime ? Date.now() - startTime : 0
    this.stimulate('EventAttendance#left!', durationInMilliseconds)
  }

  get storageKey() {
    return "event" + this.element.dataset.eventId + "StartTime"
  }
}
