import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    message: String
  }

  connect () {
    console.log('connecting', this.messageValue)
  }

  confirm(event) {
    console.log("CONFIRMING", this.messageValue)
    if (!(window.confirm(this.messageValue))) {
      event.preventDefault()
      event.stopImmediatePropagation()
    }
  }
}
