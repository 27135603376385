import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    repositoryIdParam: String
  }

  connect() {
    super.connect();
  }

  update(event) {
    event.preventDefault();
    const selectElement = event.currentTarget;
    const repositoryIdParam = selectElement.value || this.repositoryIdParamValue;
    const url = new URL(window.location);
    if (repositoryIdParam) {
      url.searchParams.set('repo_id', repositoryIdParam);
    } else {
      url.searchParams.delete('repo_id'); // all repositories = no param
    }
    window.location = url.toString();
  }
}