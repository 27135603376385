import ApplicationController from './application_controller'
// From https://popper.js.org/
import { createPopper } from '@popperjs/core'

export default class extends ApplicationController {
  static values = {
    class: String,
    url: String,
    id: String,
    placement: String,
    text: String
  }

  connect() {
    super.connect()

    this.defaultState = document.querySelector('#tooltip .default_state')
    this.tooltip = document.querySelector('#tooltip')
    this.tooltipContent = document.querySelector('#tooltip_content')
    this.tooltipContent.setAttribute('role', 'tooltip')

    this.showEvents.forEach(event => {
      this.element.addEventListener(event, this.showTooltip.bind(this))
    })

    this.hideEvents.forEach(event => {
      this.element.addEventListener(event, this.hideTooltip.bind(this))
    })
  }

  showTooltip() {
    if(this.hasUrlValue) {
      this.tooltipContent.innerHTML = this.defaultState.innerHTML
      this.tooltipContent.setAttribute('src', this.urlValue)
    } else if(this.hasIdValue) {
      this.tooltipContent.innerHTML = document.querySelector('#' + this.idValue).innerHTML
    } else if (this.hasTextValue) {
      this.tooltipContent.innerHTML = this.textValue
    } else {
      this.tooltipContent.innerHTML = "<p>There's no content to show!</p>"
    }

    this.tooltip.className = this.classValue;

    this._instance = createPopper(
      this.element,
      this.tooltip,
      {
        placement: this.placement
      }
    )

    // Enable the event listeners, so tooltip geometry gets updated
    this._instance.setOptions({
      modifiers: [{ name: 'eventListeners', enabled: true }],
    });

    this.tooltip.setAttribute('data-show', '')
    this._instance.update() // Tell popper to update the tooltip position, or else it'll be wrong
  }

  hideTooltip() {
    this.tooltip.removeAttribute('data-show')
    this.tooltipContent.removeAttribute('src')
    this.tooltipContent.innerHTML = this.defaultState.innerHTML

    // Disable the event listeners, so we don't use CPU resources we don't need
    this._instance.setOptions({
      modifiers: [{ name: 'eventListeners', enabled: false }],
    });
  }

  get placement() {
    if(this.hasPlacementValue) {
      return this.placementValue
    } else {
      return 'top'
    }
  }

  get showEvents() {
    return ['mouseenter', 'focus']
  }

  get hideEvents() {
    return ['mouseleave', 'blur']
  }
}
