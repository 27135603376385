import ApplicationController from './application_controller'
import DataTable from 'datatables.net-dt'
import 'datatables.net-buttons/js/dataTables.buttons'
import 'datatables.net-buttons/js/buttons.colVis'

export default class extends ApplicationController {
  static values = {
    config: String,
    defaultSortColumnIndex: {type: Number, default: null},
    hiddenColumns: Array,
    stateSave: Boolean
  }

  _table = null

  connect() {
    super.connect()
    this._initTable()
  }

  disconnect() {
    super.disconnect()
    this._destroyTable()
  }

  _initTable() {
    if (this._table) return

    const tableContent = this.element.tagName === 'TABLE' ? this.element : this.element.querySelector('table')
    if (!tableContent) return

    const thead = tableContent.querySelector('thead')
    const tr = thead ? thead.querySelector('tr') : null
    
    if (!thead || !tr) return

    const columnCount = tr.children.length
    const hiddenColumns = this.hiddenColumnsValue.filter(index => index < columnCount)
    const anyHiddenColumns = hiddenColumns.length > 0

    // Only include the colvis button if there are hidden columns
    const buttons = [
      {
        text: "\u21BA Reset",
        action: (e, dt, node, config) => {
          this.clearState();
        }
      },
      {
        extend: 'collection',
        text: 'Export',
        buttons: ['csv', 'copy']
      },
      'pageLength'
    ]

    if (anyHiddenColumns) {
      buttons.push({
        extend: 'colvis',
        text: 'Show/Hide Columns'
      })
    }

    const order = this.defaultSortColumnIndexValue !== undefined
      ? [[this.defaultSortColumnIndexValue, 'desc']]
      : []

    this._table = new DataTable(tableContent, {
      colReorder: true,
      autoWidth: false,
      order: order,
      stateSave: this.stateSaveValue,
      layout: {
        topStart: {
          buttons: buttons
        }
      },
      columnDefs: [
        {
          targets: hiddenColumns,
          visible: false
        }
      ]
    });
  }

  _destroyTable() {
    if (this._table) {
      this._table.destroy()
      this._table = null
    }
  }

  clearState() {
    if (this._table) {
      this._table.state.clear();
      window.location.reload();
    }
  }

  get table() {
    return this._table
  }
}
