import ApplicationController from './application_controller'

// Connect this controller to a <select> element to use the <select> to drive navigation. When the <select> value is
// changed, the controller will navigate to the currently selected <option>'s value, which is assumed to be a URL.
export default class extends ApplicationController {
  connect() {
    super.connect()
    this.processChange = this.processChange.bind(this)
    this.element.addEventListener('change', this.processChange)
  }

  disconnect() {
    this.element.removeEventListener('change', this.processChange)
    super.disconnect()
  }

  processChange() {
    Turbo.visit(this.element.value)
  }
}

