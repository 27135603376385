import ApplicationController from './application_controller'

// Intended to give us visibility into when users click links in text blobs. Mostly those links are going to be
// external links. For internal links, we will see the page load on the receiving side, but for external links,
// we have to report the data ourselves. The controller will use whatever pingUrlValue is provided, and append
// the link text to it as a query parameter (eg '/ping' => '/foo?link_text=My%20Link%20Text').
//
// The provided pingUrlValue must be a fully qualified URL (including the protocol & domain)
export default class extends ApplicationController {
  static values = {
    instrumentationRoot: String, // Will default to '.trix-content'
    pingUrl: String
  }

  connect() {
    super.connect()
    // TODO: call _instrumentLinks() in response to turbo:load events, so we can instrument dynamically added markup
    this._instrumentLinks()
  }

  _instrumentLinks() {
    let instrumentationRoot = this.hasInstrumentationRootValue ? this.instrumentationRootValue : '.trix-content'

    let links = this.element.querySelectorAll(instrumentationRoot + ' a:not([ping])')

    links.forEach(linkElement => {
      let pingTarget = new URL(this.pingUrlValue)
      pingTarget.searchParams.append('link_text', linkElement.textContent)

      linkElement.addEventListener('click', event => {
        fetch(pingTarget, {method: 'POST'}) // Telemetry collection: fire and forget
      })
    })
  }
}
